<template>
  <div id="subpage-content">
    <InformsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NewsCard2") }}</h1>
        <div id="news-contain">
          <img
            height="200"
            width="230"
            :src="getImageUrlbyName('NewsBig2')"
            href=""
            id="pic"
            align="left"
          />
          <div id="innews">
            <div id="inf-news-carddate">{{ $t("message.CNDate2") }}</div>
          </div>
          <span id="pad" v-html="$t('message.News2Text1')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News2Text2')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News2Text3')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News2Text4')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News2Text5')"></span>
          <span id="nopad" v-html="$t('message.News2Text6')"></span>
          <div id="innews">
          <a id="backto" @click="$router.push('/informs/news')">
            <v-icon large>mdi-arrow-left-bold-circle</v-icon>
            <a id = "pad-left">{{ $t("message.BackToList") }}</a>          
          </a>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import InformsDrawer from "../../../../views/Navigations/Informs/InformsDrawer";
export default {
  components: {
    InformsDrawer,
  },
};
</script>
<style>

</style>